.dx-field-item .dx-toggle-controls-paddings {
  padding: 10px 0 0;
}
.dx-field-item .dx-toggle-controls-paddings {
  padding-inline-start: 0px!important;
}

.dx-card h3 {
  margin: 0;
  padding: 7px 0;
  font-size: 16px !important;
  width: 70%;
  position: relative;
}

.dx-fileuploader-files-container {
  display: none;
}

.btn-flex {
  display: flex;
  justify-content: space-between;
}

.title-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-detail > div {
  margin-right: 15px;
}
